import styled from "styled-components";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import MagicVideo from "../MagicVideo";
import { useMemo } from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { stripWrappingParagraphTag } from "../../utils/contentful";

const HeroContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 750px /* Grid S Tablet */) {
    height: 240px;
    flex-direction: row;
    gap: var(--spacing-2, 32px);
  }

  @media (min-width: 933px) {
    height: 320px;
  }
`;

const HeroTextContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-0_5, 8px);
  margin-top: var(--spacing-1_5, 24px);

  .typography-eyebrow2 {
    min-width: 175px;
  }

  span {
    color: var(--indigo-blue-60);
  }

  h1 {
    color: var(--indigo-blue);
  }

  @media (min-width: 750px /* Grid S Tablet */) {
    margin-top: unset;
    max-width: 380px;
  }

  @media (min-width: 993px) {
    h1 {
      font-size: 4.125rem;
      line-height: 1.1em;
    }
  }
}
`;

const HeroDescription = styled.div`
  margin-top: var(--spacing-0_5);

  @media (min-width: 750px /* Grid S Tablet */) {
    margin-top: var(--spacing-1_5, 24px);
  }
`;

const HeroVisualContent = styled.div`
  order: -1;
  height: 200px;
  width: 100%;

  img,
  video {
    width: 100%;
  }

  @media (min-width: 750px /* Grid S Tablet */) {
    order: 1;
    gap: var(--spacing-2, 32px);
    height: 100%;
    width: 100%;
  }
`;

const HeroImageContainer = styled.div`
  display: block;
  width: calc(100% + 34px);
  transform: translateX(-17px);
  height: 100%;
  position: relative;

  @media (min-width: 750px /* Grid S Tablet */) {
    width: 100%;
    transform: unset;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

type Props = {
  contentfulPlp: {
    pageTitle: {
      raw: string;
    };
    description: string;
    heroImage: {
      gatsbyImageData: IGatsbyImageData;
      description: string;
    };
    heroVideo: {
      captions?: any;
      description?: {
        description: string;
      };
      stream: {
        videoUpload: {
          playbackId: string;
        };
      };
      title: string;
      videoLength?: number;
    };
  };
  activePanel: {
    title: string;
  };
  isPrimaryPage: boolean;
};

const Hero = ({ contentfulPlp, activePanel, isPrimaryPage }: Props) => {
  const { pageTitle, description, heroImage, heroVideo } = contentfulPlp;

  const videoProps = {
    muted: true,
    playsInline: true,
    autoPlay: true,
    preload: "auto",
    controls: false,
    loop: true,
  };

  const videoStyles = {
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const breadcrumb = useMemo(() => {
    if (isPrimaryPage) {
      return "Shop";
    }
    if (activePanel?.title) {
      return `Shop / ${activePanel.title}`;
    }
    return "";
  }, [isPrimaryPage, activePanel]);

  const parsedPageTitle = stripWrappingParagraphTag(documentToHtmlString(JSON.parse(pageTitle.raw)))

  return (
    <div>
      <HeroContentContainer>
        <HeroTextContainer>
          <span className="typography-eyebrow2">{breadcrumb}</span>
          <h1 className="typography-display" dangerouslySetInnerHTML={{__html: parsedPageTitle}}></h1>
        </HeroTextContainer>
        <HeroVisualContent>
          {heroImage && !heroVideo && (
            <HeroImageContainer>
              <GatsbyImage
                image={getImage(heroImage.gatsbyImageData) as IGatsbyImageData}
                alt={heroImage?.description || ""}
              />
            </HeroImageContainer>
          )}
          {heroVideo && (
            <HeroImageContainer >
              <MagicVideo
                {...heroVideo}
                {...videoProps}
                videoElementStyle={videoStyles}
              />
            </HeroImageContainer>
          )}
        </HeroVisualContent>
      </HeroContentContainer>
      <HeroDescription>
        <div className="row">
          <div className="col-12 col-sm-10 col-md-8">
            <p className="typography-lead2">{description}</p>
          </div>
        </div>
      </HeroDescription>
    </div>
  );
};

export default Hero;
