import { isEmpty } from "lodash";
import intlService from "../../services/intl";
import { getPromotionDetails } from "../../utils/promotion";

const useSeoPricing = (price, promotion) => {
  const amount = price?.toString();

  if (isEmpty(promotion)) {
    return { amount, discountAmount: null, priceValidUntil: null };
  }

  const { discountPrice } = getPromotionDetails(promotion, price);
  const formattedDiscountPrice = intlService
    .formatCurrency(discountPrice, { round: true })
    .slice(1);
  const priceValidUntil = promotion.expiresAt
    ? new Date(promotion.expiresAt).toISOString()
    : null;

  return {
    amount: price?.toString(),
    discountAmount: formattedDiscountPrice.toString(),
    priceValidUntil,
  };
};

// Non-hooks version
export const seoPricing = (price, promotion) => {
  const amount = price?.toString();

  if (isEmpty(promotion)) {
    return { amount, discountAmount: null, priceValidUntil: null };
  }

  const { discountPrice } = getPromotionDetails(promotion, price);
  const formattedDiscountPrice = intlService
    .formatCurrency(discountPrice, { round: true })
    .slice(1);
  const priceValidUntil = promotion.expiresAt
    ? new Date(promotion.expiresAt).toISOString()
    : null;

  return {
    amount: price?.toString(),
    discountAmount: formattedDiscountPrice.toString(),
    priceValidUntil,
  };
};

export default useSeoPricing;
